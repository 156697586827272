import React from "react";
import {Helmet} from 'react-helmet'

import Layout from '../components/layout.js'
import ImageQuery from '../components/imageQuery'

const AboutPage=() => {
    return (
        <Layout>
            <Helmet>
                <title>Wulkanizacja Tychy - Bląd 404</title>

                <meta name="description" content="W serwisie strona o podanym adresie nie istnieje. Natomiast nasza wukanizacja istnieje już od ponad 25 lat." />
                <meta name="keywords" content="serwis, wulkanizacja, wulkanizacja Tychy, zamiana opon, zmiana opon Tychy, serwis opon, serwis opon Tychy,  wulkanizator Tychy, Tychy wulkanizacja, naprawa opon, przechowywanie opon" />
            </Helmet>

            <section id="content">
                <div class="indent-bottom">
                    <div class="main border-rad">
                        <div class="container_12">
                            <ImageQuery
                                imgName="notFoundImage"
                                alt="obraz dla nie znaleziono strony"
                            />
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    )
}

export default AboutPage
